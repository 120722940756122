@import "./variables.scss";
@import "./common.scss";

body {
  background: $primary;
  margin: $appContainerBodyMargin;
}

.appContainer {
  height: $appContainerHeight;
}

/* Header Container */
.headerContainer {
  height: $headerContainerHeight;
  padding-top: $headerContainerPaddingTop;
  padding-left: $headerContainerPaddingLeft;
  background: $primary;
}

.headerContainer img {
  margin-top: $headerContainerImgMarginTop;
  margin-left: $headerContainerImgMarginLeft;
  padding-top: $headerContainerImgPaddingTop;
  height: $headerContainerImgHeight;
  width: $headerContainerImgWidth;
  float: $headerContainerImgFloat;
}

.seal {

  margin-top: 50px;
  float: right;
}

.seal img {
  width: 100%;
  
}

/* Footer Container */
.footerContainer {
  height: $footerHeight;
  background: $footerBackground;
  clip-path: $footerPath;
  position: relative;
  z-index: 1;
}

.footerContainerLeft img {
  margin-top: $footerImgLeftMarginTop;
  margin-left: $footerImgLeftMarginLeft;
  height: $footerImgLeftHeight;
  width: $footerImgLeftWidth;
  float: $footerImgLeftFloat;
}
.footerContainerRight img {
  margin-top: $footerImgRightMarginTop;
  height: $footerImgRightHeight;
  width: $footerImgRightWidth;
  float: $footerImgRightFloat;
  opacity: 0.7;
  display: flex;
  
  // background-image: url("../../assets/images/SaicSquareBackground.png");
  // background-repeat: repeat-x;
  // background-position: cover;
}

/* Menu Container */
.menuContainer .MuiIconButton-root {
  color: $menuIconButtonColor;
}

.menuContainer a,
a:link,
a:visited {
  color: $primary;
  font-size: $menuContainerFontSize;
  text-decoration: $menuContainerTextDecoration;
  text-align: $menuContainerTextAlign;
}

.menuContainer a:hover {
  color: $primary;
  font-size: $menuContainerFontSize;
  text-decoration: $menuContainerTextDecoration;
  background: $menuContainerHoverBackground;
  text-align: $menuContainerTextAlign;
}

/* Content Container */
.contentContainer {
  margin: $contentContainerMargin;
  height: $contentContainerHeight;
  padding: $contentContainerPadding;
  background: $background;
}

/* Card component */
.cardComponent {
  width: $cardCompWidth;
  height: $cardCompHeight;
  background: $cardCompBackground;
  position: $cardCompPosition;
}

.cardComponent:hover {
  background: $cardCompBackgroundHover;
}

/* Home Page */
.homePageContainer {
  margin: $homePageContainerMargin;
  height: $homePageContainerHeight;
  padding-bottom: $homePageContainerPaddingBottom;
}

/* Dashboard Page */
.dashboardBanner {
  margin: $dashboardBannerMargin;
  border-radius: $dashboardBannerBorderRadius;
  background-image: $dashboardBannerBackgroundImage;
  background-position: $dashboardBannerBackgroundPosition;
  background-repeat: $dashboardBannerBackgroundRepeat;
  background-size: $dashboardBannerBackgroundSize;
  height: $dashboardBannerHeight;
  width: $dashboardBannerWidth;
}

.dashboardBanner img {
  margin: $dashboardBannerImgMargin;
  width: $dashboardBannerImgWidth;
  height: $dashboardBannerImgHeight;
}

.dashboardBanner h5 {
  font-size: $dashboardBannerH5FontSize;
  color: $dashboardBannerH5Color;
  padding-bottom: $dashboardBannerH5PaddingBottom;
  align-items: $dashboardBannerH5AlignItems;
  text-align: $dashboardBannerH5TextAlign;
}

.dashboardBanner .introMessage {
  margin: $dashboardIntroMsgMargin;
  width: $dashboardIntroMsgWidth;
  font-size: $dashboardIntroMsgFontSize;
  font-weight: $dashboardIntroMsgFontWeight;
  line-height: $dashboardIntroMsgLineHeight;
  color: $dashboardIntroMsgColor;
  text-align: $dashboardIntroMsgTextAlign;
  font-family: $dashBoardIntroMsgFontFamily;
  text-shadow: $dashboardIntroMsgTextShadow;
}

.dashboardContainer .tiles {
  display: flex;
  align-items: center;
  padding: 70px 0;
  margin: -150px auto;
  width: 75%;
  height: 75vh;
}

.dashboardContainer .tiles .MuiTypography-h4 {
  padding: $dashboardContainerTilesH4Padding;
  margin-top: $dashboardContainerTilesH4MarginTop;
  font-weight: $dashboardContainerTilesH4FontWeight;
  font-size: $dashboardContainerTilesH4FontSize;
  color: $dashboardContainerTilesH4Color;
}

.dashboardContainer .tiles .MuiPaper-root .MuiTypography-body1 {
  margin-top: $dashboardContainerTilesBodyMarginTop;
  text-align: $dashboardContainerTilesBodyTextAlign;
  padding: $dashboardContainerTilesBodyPadding;
  font-weight: $dashboardContainerTilesBodyFontWeight;
  font-size: $dashboardContainerTilesBodyFontSize;
  color: $dashboardContainerTilesBodyColor;
}

.dashboardContainer .tiles .MuiPaper-root .MuiButton-root {
  margin-top: $dashboardButtonRootMarginTop;
  padding: $dashboardButtonRootPadding;
  width: $dashboardButtonRootWidth;
  height: $dashboardButtonRootHeight;
}
.dashboardContainer .tiles .MuiPaper-root .MuiButton-root:hover {
  margin-top: $dashboardButtonRootMarginTop;
  padding: $dashboardButtonRootPadding;
  background: $SAICGreen;
  color: $primary;
}

.dashboardContainer .tiles .caseSearch .MuiPaper-root {
  padding: $dashboardCaseSearchPadding;
  height: 235px;
  margin: 0 auto;
  // width: 75%;
  border-radius: $dashboardCaseSearchBorderRadius;
  transition: all 0.3s ease-in-out;
  background-image: linear-gradient(to right, transparent, #f5faff, #edf8ff);
}
.dashboardContainer .tiles .caseSearch .MuiPaper-root:hover {
  padding: $dashboardCreateCasePadding;
  height: 235px;
  // width: 77%;
  border-radius: $dashboardCreateCaseBorderRadius;
  background: #fff;
  transition: all 0.3s ease-in-out;
  // font-size: 1.1em;
}

.dashboardContainer .tiles .createCase .MuiPaper-root {
  margin-left: 75px;
  padding: $dashboardCreateCasePadding;
  height: 235px;
  margin: 0 auto;
  // width: 75%;
  border-radius: $dashboardCreateCaseBorderRadius;
  transition: all 0.3s ease-in-out;
  background-image: linear-gradient(to right, transparent, #f5faff, #edf8ff);
}
.dashboardContainer .tiles .createCase .MuiPaper-root:hover {
  padding: $dashboardCreateCasePadding;
  height: 235px;
  // width: 77%;
  border-radius: $dashboardCreateCaseBorderRadius;
  background: #fff;
  transition: all 0.3s ease-in-out;
  // font-size: 1.1em;
}

.dashboardContainer .foiaTable {
  margin: $dashboardContainerFOIATableMargin;
}

/* FOIA Page */
.foiaContainer {
  margin: $foiaPageContainerMargin;
  height: $foiaPageContainerHeight;
  padding-bottom: $foiaPageContainerPaddingBottom;
}

/* Case Details Page */
.caseDetailsContainer {
  margin: $caseDetailsPageContainerMargin;
  height: $caseDetailsPageContainerHeight;
}

/* Redact Page */
.redactContainer {
  margin: $redactPageContainerMargin;
  height: $redactPageContainerHeight;
  padding-bottom: $redactPageContainerPaddingBottom;
}

/* Search Page */
.searchContainer {
  margin: $searchPageContainerMargin;
  height: $searchPageContainerHeight;
  padding-bottom: $searchPageContainerPaddingBottom;
}

/* Case Search Page */
.fOIARequestCaseSearchContainer {
  margin: $FOIARequestCaseSearchPageContainerMargin;
  height: $FOIARequestCaseSearchPageContainerHeight;
  padding-bottom: $FOIARequestCaseSearchPageContainerPaddingBottom;
}

.searchBody {
  margin: $searchBodyMargin;
  height: $searchBodyHeight;
  padding-bottom: $searchBodyPaddingBottom;
}

/* Upload Page */
.uploadContentContainer {
  margin: $uploadContentContainerMargin;
}

.uploadContainer {
  margin: $uploadContainerMargin;
  align-items: $uploadContainerAlignItems;
  padding: $uploadContainerPadding;
  background: $uploadContainerBackground;
  border-radius: $uploadContainerBorderRadius;
}

/* FOIA Case page */
.foiaCaseContainer {
  height: 100%;
}

/* FOIA Case details page */
.foiaCaseDetailContainer {
  margin: $foiaCaseDetailsContainerMargin;
  height: $foiaCaseDetailsContainerHeight;
}

/* Data Table */
.tableDataContainer .MuiButton-root {
  padding: $dataTableContainerButtonPadding;
  width: $dataTableContainerButtonWidth;
  background: $dataTableContainerButtonBackground;
  color: $primary;
  margin-left: $dataTableContainerButtonMarginLeft;
}

/* FILE UPLOAD */
.dropzone {
  display: $dropzoneDisplay;
  flex-direction: $dropzoneFlexDirection;
  align-items: $dropzoneAlignItems;
  justify-content: $dropzoneJustifyContent;
  height: $dropzoneHeight;
  border: $dropzoneBorder;
  padding: $dropzonePadding;
  border-radius: $dropzoneBorderRadius;
  background-color: $dropzoneBackgroundColor;
  margin: $dropzoneMargin;
  text-align: $dropzoneTextAlign;
}

.dropzone:hover {
  background: $dropzoneHoverBackground;
  animation: fadeInAnimation ease 1s;
  .MuiIconButton-root {
    background: #ffffff;
  }
}

.dropzone .MuiTypography-root {
  font-size: 15px;
}

.dropzone .MuiButton-root {
  height: 45px;
}

.active {
  background-color: #5f5959;
}

.list-item {
  display: $liDisplay;
  padding: $liPadding;
  margin: $liMargin;
  min-width: $liMinWidth;
  font-size: $liFontSize;
  background-color: $liBackgroundColor;
  cursor: $liCursor;
  border-radius: $liBorderRadius;
}

.list-item > h3 {
  padding: $liH3Padding;
  margin: $liH3Margin;
}

.list-item > span {
  margin-right: $liSpanMarginRight;
  font-size: $liSpanFontSize;
  font-weight: $liSpanFontWeight;
  border-right: $liSpanBorderRight;
  padding: $liSpanPadding;
  padding-right: $liSpanPaddingRight;
}

.next-position {
  border: 2px solid #34d44f;
  opacity: 0.2;
}

/*Stepper Component*/
.stepper .css-8t49rw-MuiStepConnector-line {
  margin-left: 100px;
}

/* Query Builder */
.queryBuilderContainer {
  border: none;
  width: 100%;
  margin: 0 auto;
  background: $background;
  border-radius: 10px;
  padding: 10px;
}

.queryBuilder {
  font-size: 16px;
  width: 75%;
  background: #ffffff;
  margin: 0 auto;
  align-items: center;
  border: none;
}

.ruleGroup-header button {
  padding: 12px;
  width: 25%;
  background: $primary;
  border-radius: 5px;
  border: none;
  color: #fff;
}

.ruleGroup-combinators {
  background: #f2f0f0;
  color: #333;
  width: 20%;
  padding: 12px;
  border: 1px solid #f2f0f0;
  border-radius: 4px;
}

.rule-operators {
  margin: 0 auto;
  width: 50%;
  height: 30px;
  background: #fcfcfc;
}

.rule-fields {
  width: 50%;
  height: 30px;
  background: #fcfcfc;
}

.rule-value {
  width: 100%;
  height: 30px;
  background: #fcfcfc;
}

.rule-remove {
  background: #d24444;
  color: #fff;
  border-radius: 5px;
  font-size: 16px;
  width: 30px;
  height: 25px;
}

.ruleGroup-header .ruleGroup-remove {
  background: #d24444;
  color: #fff;
  width: 75px;
  height: 40px;
}

.queryBuilderResultsContainer {
  overflow: auto;
  scroll-behavior: smooth;
}

/* Case Dashboard */
.caseDashboardContainer {
  height: 100%;
  margin: 0 auto;
}

.caseDashboardIndexContainer {
  height: 100%;
}

.css-1equabv-MuiGrid-root {
  max-width: 15%;
}
